import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/graphics/logo.svg'
import _imports_1 from '../assets/graphics/exhibitions.svg'
import _imports_2 from '../assets/graphics/about.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[2] || (_cache[2] = _createElementVNode("img", {
      class: "logo",
      src: _imports_0,
      alt: "laurel"
    }, null, -1)),
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("img", {
          class: "menu-link",
          src: _imports_1,
          alt: "exhibitions"
        }, null, -1)
      ])),
      _: 1
    }),
    _createVNode(_component_router_link, { to: "/about" }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          class: "menu-link",
          src: _imports_2,
          alt: "about"
        }, null, -1)
      ])),
      _: 1
    })
  ], 64))
}